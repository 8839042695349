<template>
    <section class="forms">
        <div class="page-header">
            <h3 class="page-title">
                Edit Search Term
            </h3>
            <button type="button" class="btn btn-info believe-btn" @click="goBack()">
                <i class="mdi mdi-keyboard-backspace"></i> Back</button>
        </div>
        <div class="row">
            <div class="col-md-7 m-auto d-flex align-items-stretch grid-margin">
                <div class="row flex-grow">
                    <div class="col-12 grid-margin">
                        <div class="card">
                            <div class="card-body">
                                <b-form @submit.stop.prevent="onSubmit">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <b-form-group id="example-input-group-1" label="Search Term"
                                                label-for="searchTerm">
                                                <b-form-input id="searchTerm" name="searchTerm"
                                                    v-model="$v.form.searchTerm.$model"
                                                    :state="$v.form.searchTerm.$dirty ? !$v.form.searchTerm.$error : null"
                                                    aria-describedby="input-1-live-feedback"></b-form-input>

                                                    <div v-if="$v.form.searchTerm.$error" class="invalid-feedback">
                                                        <div v-if="!$v.form.searchTerm.required">Search Term is required</div>
                                                        <div v-if="!$v.form.searchTerm.maxLength">Maximum limit 200 characters</div>
                                                    </div>
                                            </b-form-group>
                                        </div>

                                        <div class="col-md-12">
                                            <b-form-group id="example-input-group-1" label="Count"
                                                label-for="searchCount">
                                                <b-form-input id="searchCount" name="searchCount"
                                                    v-model="$v.form.searchCount.$model"
                                                    :state="$v.form.searchCount.$dirty ? !$v.form.searchCount.$error : null"
                                                    aria-describedby="input-1-live-feedback"></b-form-input>

                                                    <div v-if="$v.form.searchCount.$error" class="invalid-feedback">
                                                        <div v-if="!$v.form.searchCount.required">Count is required</div>
                                                        <div v-if="!$v.form.searchCount.numeric">Count must be number</div>
                                                    </div>

                                            </b-form-group>
                                        </div>
                                      
                                    </div>


                                    <b-button :disabled='is_loading' type="submit" variant="success"
                                        class="btn-block believe-btn">
                                        <i
                                            :class="is_loading ? 'circle-loader' : 'mdi mdi-file-check btn-icon-prepend'"></i>
                                        Submit
                                    </b-button>
                                </b-form>
                                <vue-snotify></vue-snotify>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required , maxLength, numeric} from 'vuelidate/lib/validators'
import api from "../../../config/api.js";

export default {
    name: 'EditSearchTerm',
    mixins: [validationMixin],
    data() {
        return {
            pageNumber : "", // search &
            count: 0,
            msg: "",
            is_loading: false,
            form: {
                id : null,
                searchCount : null,
                searchTerm : null,
            }
        }
    },

    validations: {
        form: {
            searchTerm: {
                required,
                maxLength : maxLength(200)
            },
            searchCount: {
                required,
                numeric
            },
        }
    },
  
    mounted() {
        this.items();
    },

    methods: {
        updateTagFromChild(arr) {
            this.tags = arr;
        },

        async items() {
            let data = await this.$store.getters['getEditFormData'];
            if (data.length == 0) {
                data = localStorage.getItem('data_for_edit');
                data = JSON.parse(data);
            }
            this.pageNumber = data?.pageNumber,
            this.form.searchTerm = data?.queries;
            this.form.searchCount = data?.count;
            this.form.id = data?.id;
        },

    
        goBack() {
            let prev_url = '/all-search-terms';
            this.$store.state.pageNumberAfterAction = this.pageNumber; // search &
            this.$router.push({ path: prev_url });
        },

        async onSubmit() {
            this.$v.form.$touch()
            if (this.$v.form.$anyError) {
                return false;
            }
                try {
                    this.is_loading = true;
                    let endpoint = "/admin/update-search-term";
                    const formData = new FormData();
                    formData.append("id", this.form?.id);
                    formData.append("term", this.form?.searchTerm);
                    formData.append("count", this.form?.searchCount);
                    let result = await api.post(endpoint, formData);
                    const msg = await result.data.message;
                    this.$store.dispatch('setMessage', { msg });
                    let prev_url = `/all-search-terms`;
                    this.$store.state.pageNumberAfterAction = this.pageNumber; // search &
                    this.$router.push({ path: prev_url });
                    this.is_loading = false;
                } catch (error) {
                    console.log(error);
                }
        },

    },




}
</script>